import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga4';

const root = ReactDOM.createRoot(document.getElementById('root'));

ReactGA.initialize('G-X9QEK7V612');

function addRedditPixel() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.redditstatic.com/ads/pixel.js';
    script.onload = () => {
        if (window.rdt) {
        window.rdt('init', 'a2_f87liuovkmnw');
        window.rdt('track', 'PageVisit');
        }
    };
    document.head.appendChild(script);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
        !function(w,d,e,o,t){w.RdtObject=o;var p=w[o]=w[o]||function(){(p.q=p.q||[]).push(arguments)};
        p.l=1*new Date();t=d.createElement(e);var f=d.getElementsByTagName(e)[0];
        t.async=1;t.src="https://www.redditstatic.com/ads/pixel.js";f.parentNode.insertBefore(t,f)}(window,document,"script","rdt");
    `;
    document.head.appendChild(inlineScript);
}

// Call the function to add the Reddit Pixel
addRedditPixel();

root.render(
    <App />
);

reportWebVitals();
